<template>
  <UiPopup
    :model-value="modelValue"
    :title="$t('cancel_deal')"
    :description="$t('cancel_deal_description')"
    :primary-button-text="retrieving ? '' : $t('delete')"
    :secondary-button-text="retrieving ? '' : $t('keep_working')"
    size="small"
    :loading="saving"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <transition name="fade" mode="out-in">
      <UiLoader v-if="retrieving" />
      <form v-else ref="form" @submit.prevent>
        <UiInputSelect
          v-model="data.reason_id"
          :items="reasonsItems"
          name="Reason"
          placeholder="Choose reason"
          class="mb-4"
          :error="useGetFieldErrors(v$, ['reason_id'])"
        />
        <UiInputTextField v-model="data.reason_note" name="Other" placeholder="or add other reason" class="mb-4" />
      </form>
    </transition>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useUiStore } from '@/store/ui'

import type { Deal, InputItem } from '@/types'
import { CUSTOM_EVENTS } from '~/constants'

const uiStore = useUiStore()
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  deal: Deal
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const retrieving = ref(true)
const saving = ref(false)
const data = ref({
  reason_id: undefined,
  reason_note: '',
})
const reasonsItems = ref<InputItem[]>([])

onNuxtReady(() => getReasons())

const getReasons = async () => {
  reasonsItems.value = await useGetFinanceDealReasonsItems()
  retrieving.value = false
}

const rules = computed(() => ({
  reason_id: { required: helpers.withMessage('The reason is required', required) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  if (!(await v$.value.$validate())) return
  saving.value = true
  try {
    await useCancelDeal(Number(props.deal.id), data)
    document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_DEAL_DATA))
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    saving.value = false
  }
}
</script>

<style scoped></style>
